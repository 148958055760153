import React from "react"
import Header from "../../components/header"
import {Helmet} from "react-helmet"

export default () => (
  <React.Fragment>
    <Helmet>
      <title>Commissioner Update - 2019 Season | Chode Dynasty</title>
    </Helmet>
    
    <Header />
    <main>
      <div className="row">
        <div className="inner">
          <h1>Commissioner Update - 2019 Season</h1>
          <nav>
            <ul>
              <li><a href="#draft">Draft</a></li>
              <li><a href="#changes">Changes</a></li>
              <li><a href="#rewards">Weekly Rewards</a></li>
            </ul>
          </nav>

          <h2 id="draft">Draft</h2>
          <p>The rookie draft will occur on Saturday, June 29th at 5:00 PM. It will be held in-person at the Commissioner's house, but you will be able to draft remotely using the Sleeper app.</p>

          <h3>Details</h3>
          <p>The rookie draft order is the reverse standings of the 2018 season.</p>
          
          <p>The rookie draft will be 4 rounds. Draft order is listed below and will be linear, meaning that the order will be the same for all rounds. You're able to trade draft picks.</p>

          <p>Veterans/free agents will be available once the season starts, which should be immediately after the draft ends.</p>

          <p>
            <ol>
              <li>Team Frank</li>
              <li>Jeffy Sessions</li>
              <li>Team Cory</li>
              <li>RAVENous Pickle</li>
              <li>Cookie Boi</li>
              <li>FART JUGS</li>
              <li>ROMOLICIOUS 9</li>
              <li>Dumpster Fire</li>
              <li>Piss Jugs</li>
              <li>Sportsball bois</li>
            </ol>
          </p>

          <h2 id="changes">Changes</h2>
          <h3>Platform Change</h3>
          <p>We've migrated the league from ESPN to Sleeper. ESPN will no longer be used. Sleeper is built for dynasty leagues and I've heard nothing but great things about it. It also has a modern chatting functionality, unlike the crap that ESPN does :)</p>

          <h3>Waiver System Change</h3>
          <p>We're moving from the standard waiver priority system to a FAAB (Free Agent Acquisition Budget) system. I suggest reading up on FAAB before next season, but a quick explanation is that we all have a $100 budget to spend on players during the regular season (after the draft). Each week, instead of placing waiver claims, you place bids on players. Highest bid on a player gets that player on their roster. Once you run out of FAAB, you can only place $0 bids or pick up free agents. FAAB works better with the dynasty format because it gives everyone a fair chance to grab players off waivers.</p>

          <h3>Playoff Seeding / Weeks</h3>
          <p>The Sleeper platform does not allow for 5/10 teams to make the playoffs like ESPN does, so we're going to migrate to playoffs that include the top 4 teams in the league. Playoffs will then occur on weeks 15 and 16, which means we have an extra week (14) in the regular season.</p>

          <h3>Roster Settings</h3>

          <h4>Removal of Kicker</h4>
          <p>As per a majority vote, we've removed the Kicker roster spot.</p>

          <h2 id="rewards">Weekly Rewards</h2>
          <p>We're still doing the weekly rewards where you have a chance to get a $10 payout each week for winning different challenges, however, we're going to go with a different set of challenges this year!</p>

          <p>
            <table className="styled rewards-cond">
              <thead>
                <th>Week</th>
                <th>Title</th>
                <th>Challenge</th>
              </thead>

              <tbody>
                <tr>
                  <td>1</td>
                  <td>Ready Set Go</td>
                  <td>Team with the highest score</td>
                </tr>

                <tr>
                  <td>2</td>
                  <td>Hero to Zero</td>
                  <td>Team with the biggest drop in score from Week 1 to Week 2</td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>Air It Out</td>
                  <td>Team with the QB with the longest pass</td>
                </tr>

                <tr>
                  <td>4</td>
                  <td>Gotta Catch Em All</td>
                  <td>Team with the WR with the most receptions</td>
                </tr>

                <tr>
                  <td>5</td>
                  <td>Runt of the Litter</td>
                  <td>Winning team with the lowest scoring starter</td>
                </tr>

                <tr>
                  <td>6</td>
                  <td>Got There</td>
                  <td>Team that beats its opponent by the smallest margin of victory</td>
                </tr>

                <tr>
                  <td>7</td>
                  <td>Halftime</td>
                  <td>Team with the most total points after 7 weeks</td>
                </tr>

                <tr>
                  <td>8</td>
                  <td>Blackjack</td>
                  <td>Team with a starter closest to 21 points without going over</td>
                </tr>

                <tr>
                  <td>9</td>
                  <td>The Purge</td>
                  <td>Subtract last week's score from this weeks, the team with the highest remaining score wins</td>
                </tr>

                <tr>
                  <td>10</td>
                  <td>Right On Target</td>
                  <td>Team closest to their projected score (over OR under)</td>
                </tr>

                <tr>
                  <td>11</td>
                  <td>At Least You Tried</td>
                  <td>Highest scoring losing team</td>
                </tr>

                <tr>
                  <td>12</td>
                  <td>Magic Legs</td>
                  <td>Team with the RB with the most rushing yards</td>
                </tr>

                <tr>
                  <td>13</td>
                  <td>End Zone</td>
                  <td>Team with the most offensive touchdowns scored</td>
                </tr>
              </tbody>
            </table>
          </p>

        </div>
      </div>
    </main>
  </React.Fragment>
)